<template>
  <div>
    <div class="ui-type-caption text-uppercase text-gray-600">
      {{
        $t("role.orders.rounds.ddmp.detail.tabs.products.table.count.orders", [
          getClientsCount(),
        ])
      }}
    </div>
    <span>{{
      $t("role.orders.rounds.ddmp.detail.tabs.products.table.count.clients", [
        getClientsCount(),
      ])
    }}</span>
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "ProductsTableOrders",

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getOrderCount() {
      return get(this.data, "count_orders");
    },
    getClientsCount() {
      return get(this.data, "count_families");
    },
  },
};
</script>
